<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="animated fadeIn">
		<template v-if="!loading && hasData">
			<b-row>
				<b-col
					class="d-flex"
					:class="['xs', 'sm'].includes(windowWidth) ? 'col-12' : 'col-5'"
					style="gap:10px;">
					<b-button
						class="float-right mb-4"
						variant="primary"
						@click="!redirect ? $router.go(-1) : $router.push({ name: 'Orders' })">
						<span class="fa fa-arrow-left" />
					</b-button>
					<h5 :class="['xs', 'sm'].includes(windowWidth) ? 'mb-3' : 'mt-2'">
						<b style="margin-right: 10px;">{{ `${translate('invoice_no')} ${data.attributes.order_info.invoice_no}` }}</b>
						<span
							v-if="data.attributes.is_fraud"
							class="badge badge-danger mr-2">{{ translate('order_is_fraud') }}</span>
						<span
							v-if="data.attributes.is_invisible"
							class="badge badge-secondary">{{ translate('order_is_invisible') }}</span>
					</h5>
				</b-col>
				<b-col class="col-7 d-flex justify-content-end">
					<template
						v-if="isAdmin
							&& showApprovalButtons
							&& $can('orders','verify')
							&& !['xs', 'sm'].includes(windowWidth)">
						<b-button
							variant="success"
							class="bg-success-alt mb-4 mr-2"
							@click="approveOrder">
							<i class="fas fa-check mr-1" />
							<span>{{ translate('approve') }}</span>
						</b-button>
						<b-button
							variant="danger"
							class="mb-4 mr-2"
							@click="rejectOrder">
							<i class="fas fa-times mr-1" />
							<span>{{ translate('reject') }}</span>
						</b-button>
					</template>
					<b-button
						v-if="isAdmin
							&& $can('orders','flag')
							&& showMarkAsFraudButton
							&& !['xs', 'sm'].includes(windowWidth)"
						class="mb-4 mr-2"
						variant="danger"
						@click="fraudOrder">
						<span>{{ translate('mark_as_fraud') }}</span>
					</b-button>
					<b-button
						v-if="isAdmin && $can('orders','complete') && canCompleteOrder && !['xs', 'sm'].includes(windowWidth)"
						class="bg-primary-alt mb-4 mr-2"
						@click="completeOrder">
						<span>{{ translate('mark_order_as_complete', { orderId: currentOrderId }) }}</span>
					</b-button>
					<router-link
						v-if="!isAdmin && data.attributes.can_payment_method_change"
						:to="{ name: isPickupOrder ? 'PSChangePaymentMethodMain' : 'ChangePaymentMethodMain', params: { order_id: data.id } }"
						class="d-none d-md-block">
						<b-button
							class="float-right mb-4 mr-1"
							variant="primary">
							{{ translate('change_payment_method') }}
						</b-button>
					</router-link>
					<router-link
						v-if="data.attributes.wallet_address"
						:to="{ name: 'CryptoPaymentsDetails', params: { wallet_address: data.attributes.wallet_address } }"
						class="d-none d-md-block"
						target="_blank">
						<b-button
							:class="['xs', 'sm'].includes(windowWidth) ? '' : 'mb-4 mr-1'"
							class="float-right mb-4 mr-1"
							variant="primary">
							<i
								class="fab fa-bitcoin"
								aria-hidden="true" />
							{{ translate('crypto_payment_details') }}
						</b-button>
					</router-link>
				</b-col>
			</b-row>
			<b-row class="d-flex d-md-none">
				<template
					v-if="isAdmin
						&& $can('orders','verify')
						&& showApprovalButtons">
					<b-col
						class="col-12 mb-2">
						<b-button
							class="bg-success-alt btn-block"
							variant="success"
							@click="approveOrder">
							<i class="fas fa-check mr-1" />
							<span>{{ translate('approve') }}</span>
						</b-button>
					</b-col>
					<b-col class="col-12 mb-2">
						<b-button
							class="btn-block"
							variant="danger"
							@click="rejectOrder">
							<i class="fas fa-times mr-1" />
							<span>{{ translate('reject') }}</span>
						</b-button>
					</b-col>
				</template>
				<b-col
					v-if="isAdmin
						&& $can('orders','flag')
						&& showMarkAsFraudButton"
					class="col-12 mb-2">
					<b-button
						class="btn-block"
						variant="danger"
						@click="fraudOrder">
						<span>{{ translate('mark_as_fraud') }}</span>
					</b-button>
				</b-col>
				<b-col
					v-if="isAdmin
						&& $can('orders','complete')
						&& canCompleteOrder"
					class="col-12 mb-2">
					<b-button
						class="bg-primary-alt btn-block "
						@click="completeOrder">
						<span>{{ translate('mark_order_as_complete', { orderId: currentOrderId }) }}</span>
					</b-button>
				</b-col>
				<b-col class="col-12">
					<router-link
						v-if="!isAdmin && data.attributes.can_payment_method_change"
						:to="{ name: isPickupOrder ? 'PSChangePaymentMethodMain' : 'ChangePaymentMethodMain', params: { order_id: data.id } }">
						<b-button
							class="w-100 mb-2"
							variant="primary">
							{{ translate('change_payment_method') }}
						</b-button>
					</router-link>
				</b-col>
				<b-col class="col-12">
					<router-link
						v-if="data.attributes.wallet_address"
						:to="{ name: 'CryptoPaymentsDetails', params: { wallet_address: data.attributes.wallet_address } }"
						target="_blank">
						<b-button
							class="w-100"
							variant="primary">
							<i
								class="fab fa-bitcoin"
								aria-hidden="true" />
							{{ translate('crypto_payment_details') }}
						</b-button>
					</router-link>
				</b-col>
				<b-col class="col-12">
					<hr>
				</b-col>
			</b-row>
			<b-tabs
				v-model="currentTab"
				content-class="border-0">
				<b-tab>
					<template v-slot:title>
						<a class="list-group-item btn text-left text-gray-dark">
							{{ translate('complete_detail') }}
						</a>
					</template>
					<main-order-detail
						:data="data"
						:loading="loading"
						:has-data="hasData"
						:redirect="redirect"
						@updated="$emit('updated')"
						@go-to-sub-order="goToSubOrder" />
				</b-tab>
				<b-tab
					v-for="(item, subOrderId) in data.attributes.sub_orders"
					:key="`sub_order_${subOrderId}`">
					<template #title>
						<a class="list-group-item btn text-left text-gray-dark">
							{{ translate('order_id') }}: {{ subOrderId }} ({{ translate(item.status) }})
							<span
								v-if="item.verification_status !== null && item.status === 'document_review' || item.status === 'document_review'"
								class="badge"
								:class="`badge-${verificationStatusesColor[item.verification_status || 'pending']}`">
								{{ translate(`verification_status_${item.verification_status || 'pending'}`) }}
							</span>
						</a>
					</template>
					<sub-order-detail
						:sub-order-id="subOrderId"
						:data="data"
						:loading="loading"
						:has-data="hasData"
						:redirect="redirect"
						@updated="$emit('updated')" />
				</b-tab>
			</b-tabs>
			<div v-if="isParentMultiPayment">
				<b-alert
					show
					variant="info"
					v-html="translate('admin_fee_does_not_included', { fee: adminFee })" />
			</div>
			<div v-if="data.attributes.promotions.length > 0">
				<h4 class="">
					{{ translate('zlem_promo_title') }}
				</h4>
				<p>{{ data.attributes.promotions[0] }}</p>
			</div>
			<update-order-document-modal
				v-if="!isAdmin && showDocumentStatus && showDocumentModal"
				:order-id="data.id"
				:id-number="data.attributes.id_number"
				:first-name="data.attributes.shipping.first_name"
				:last-name="data.attributes.shipping.last_name"
				:country-code="data.attributes.country_code"
				:verification-status="data.attributes.verification_status"
				@close="showDocumentModal = false"
				@reload="$emit('updated')" />
		</template>
		<is-loading
			:loading-label="translate('loading')"
			:loading="loading"
			:has-data="hasData" />
	</div>
</template>
<script>
import DashboardRedirect from '@/mixins/DashboardRedirect';
import OrderVerification from '@/mixins/OrderVerification';
import WindowSizes from '@/mixins/WindowSizes';
import Order from '@/util/Order';
import {
	OrdersDetails, Products, PurchasePayment, PurchaseShipping, Cart, TrackingStatus, Grids,
} from '@/translations';
import { YMD_FORMAT } from '@/settings/Dates';
import { ORDER_STATUSES as statuses, ORDER_STATUSES, ORDER_VERIFICATION_STATUSES_COLORS } from '@/settings/Statuses';
import { admin, distributor } from '@/settings/Roles';
import UpdateOrderDocumentModal from './UpdateOrderDocumentModal';
import MainOrderDetail from './MainOrderDetail.vue';
import SubOrderDetail from './SubOrderDetail.vue';

export default {
	name: 'OrdersDetailsTemplate',
	messages: [OrdersDetails, Products, PurchasePayment, PurchaseShipping, Cart, TrackingStatus, Grids],
	components: {
		UpdateOrderDocumentModal, MainOrderDetail, SubOrderDetail,
	},
	mixins: [DashboardRedirect, OrderVerification, WindowSizes],
	props: {
		data: {
			type: Object,
			required: true,
		},
		loading: {
			type: Boolean,
			required: true,
		},
		hasData: {
			type: Boolean,
			required: true,
		},
		redirect: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			dateFormat: YMD_FORMAT,
			admin,
			distributor,
			statuses: ORDER_STATUSES,
			orderComplete: new Order(),
			orderFraud: new Order(),
			orderVerify: new Order(),
			alert: new this.$Alert(),
			showDocumentModal: false,
			currentTab: 0,
			verificationStatusesColor: ORDER_VERIFICATION_STATUSES_COLORS.users,
		};
	},
	computed: {
		canCompleteOrder() {
			const validStatusesToComplete = ['processing', 'payment_review', 'pick_up', 'pick_up_3pl', 'document_review', 'processing_3pl'];
			if (this.data.attributes.order_info.status === 'split') {
				if (this.currentTab === 0) {
					return false;
				}
				return validStatusesToComplete.includes(Object.values(this.data.attributes.sub_orders)[this.currentTab - 1].status);
			}
			return validStatusesToComplete.includes(this.data.attributes.order_info.status);
		},
		allowToChangeAddress() {
			return this.data && this.data.attributes && this.data.attributes.can_change_address;
		},
		showDocumentStatus() {
			return this.data.attributes.verification_status === 'valid'
				|| this.data.attributes.order_info.status === 'document_review'
				|| Object.values(this.data.attributes.sub_orders).some((item) => item.status === 'document_review');
		},
		isAdmin() {
			return this.admin.includes(this.$user.details().type);
		},
		showMarkAsFraudButton() {
			try {
				const { status } = this.data.attributes.order_info;
				return status !== statuses.pending && !this.data.attributes.is_fraud;
			} catch (error) {
				return false;
			}
		},
		showApprovalButtons() {
			try {
				const { status } = this.data.attributes.order_info;
				return status === ORDER_STATUSES.paymentReview;
			} catch (error) {
				return false;
			}
		},
		isPickupOrder() {
			return this.data.attributes.is_pickup_order;
		},
		isParentMultiPayment() {
			try {
				const { is_multi_payment: isMultiPayment, is_parent_order: isParentOrder } = this.data.attributes.multi_payment_info;
				return isMultiPayment && isParentOrder;
			} catch (error) {
				return false;
			}
		},
		adminFee() {
			try {
				return this.data.attributes.multi_payment_info.admin_fee;
			} catch (error) {
				return 0;
			}
		},
		currentOrderId() {
			let orderId = this.data.id;
			if (this.currentTab > 0) {
				orderId = Object.values(this.data.attributes.sub_orders)[this.currentTab - 1].order_id;
			}
			return orderId;
		},
	},
	watch: {
		currentTab: {
			handler(newValue) {
				this.$emit('current-tab', newValue);
				if (newValue === 0) return;
				const subOrderId = Object.values(this.data.attributes.sub_orders)[newValue - 1].order_id;
				// Update the query in the URL without reloading the page
				window.history.replaceState(null, null, `${window.location.pathname}?sub_order_id=${subOrderId}`);
				this.$route.query.sub_order_id = subOrderId;
			},
			immediate: true,
		},
		data: {
			handler() {
				if (this.data.attributes && this.data.attributes.sub_orders && this.$route.query.sub_order_id) {
					this.goToSubOrder(this.$route.query.sub_order_id);
				}
			},
			immediate: true,
		},
	},
	methods: {
		completeOrder() {
			const orderId = this.currentOrderId;
			const trans = {
				title: this.translate('mark_order_as_complete', { orderId }),
				text: this.translate('password'),
			};
			const options = {
				buttonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
				emptyPasswordMessage: this.translate('password_is_required'),
			};

			this.alert.confirmationWithPassword(trans.title, trans.text, options).then((password) => {
				const payload = {
					password: password.value,
				};

				this.orderComplete.complete(orderId, payload).then(() => {
					this.alert.toast('success', this.translate('order_updated'));
					this.$emit('updated');
				}).catch(() => {
					let errorName = 'default_error_message';
					if (typeof this.orderComplete.data.errors.errors.password !== 'undefined') {
						errorName = 'incorrect_password';
						delete this.orderComplete.data.errors.errors.password;
					}
					this.alert.toast('error', this.translate(errorName));
				});
			}).catch(() => {});
		},
		fraudOrder() {
			const trans = {
				title: this.translate('mark_as_fraud_alert_title'),
				password: this.translate('password'),
				notesTitle: this.translate('notes'),
			};
			const options = {
				buttonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
				emptyPasswordMessage: this.translate('password_is_required'),
				message: this.translate('mark_as_fraud_alert_message'),
			};

			this.alert.confirmationWithPasswordAndNotes(trans.title, trans.password, trans.notesTitle, options).then((response) => {
				const { value } = response;
				const payload = {
					notes: value.notes,
					password: value.password,
				};
				this.orderFraud.fraud(this.data.id, payload).then(() => {
					this.alert.toast('success', this.translate('order_updated'));
					this.data.attributes.is_fraud = !this.data.attributes.is_fraud;
					this.$emit('updated');
				}).catch(() => {
					let errorName = 'default_error_message';
					if (typeof this.orderFraud.data.errors.errors.password !== 'undefined') {
						errorName = 'incorrect_password';
						delete this.orderFraud.data.errors.errors.password;
					}
					this.alert.toast('error', this.translate(errorName));
				});
			}).catch(() => {});
		},
		approveOrder() {
			this.approveOrderVerification(this.data.id, { onSuccess: () => { this.$emit('updated'); } });
		},
		rejectOrder() {
			this.rejectOrderVerification(this.data.id, { onSuccess: () => { this.$emit('updated'); } });
		},
		goToSubOrder(subOrderId) {
			const index = Object.values(this.data.attributes.sub_orders).findIndex((item) => Number(item.order_id) === Number(subOrderId));
			this.currentTab = index + 1;
		},
	},
};
</script>
